@font-face {
    font-family: 'Work Sans Thin';
    src: url('WorkSans-ExtraLight.ttf');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Light.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans';
}

.home-box {
    margin-top: 80px;
    padding: 10vw;
    width: 100%;
    position: relative;
}

.home-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    text-align: center;
}

.h1 {
    color: #474ED2;
    -webkit-user-select: none;
    user-select: none;
    font-family: 'Work Sans Thin';
    margin: 10px;
    font-style: normal;
    font-weight: 100;
    text-shadow: 0px 1.84083px 1.84083px rgba(0, 0, 0, 0.25);
    font-size: 7vw;
}

.home-content p {
    width: 50%;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 152.3%;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #444BD5;
}

.upload-button {
    height: 50px;
    width: 200px;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #7a4de0;
    overflow: hidden;
    border-radius: 30px;
    /* color: #6A63ED; */
    transition: all 0.5s ease-in-out;
    margin-top: 24px;
    /* background: conic-gradient(from 179.03deg at 37.84% 46.85%, rgba(39, 217, 180, 0.54) -21.53deg, #9397EE 13.12deg, #6860F9 175.8deg, rgba(31, 185, 166, 0.49) 324.38deg, rgba(39, 217, 180, 0.54) 338.47deg, #9397EE 373.13deg); */

}

.btn-txt {
    z-index: 1;
    font-weight: 400;
    font-size: 18px;
}

/* animition from color #551A8B to #fff */
@keyframes button-colorTransition {
    from {
        color: #551A8B;
    }

    to {
        color: #fff;
    }
}

.upload-button:hover {
    box-shadow: 1px 1px 200px #252525;
    border: none;
}

.upload-button:hover .btn-txt {
    animation: button-colorTransition 0.5s ease-in-out forwards;
}

.type1::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    /* background-color: #6A63ED; */
    background: conic-gradient(from 179.03deg at 37.84% 46.85%, rgba(39, 217, 180, 0.54) -21.53deg, #9397EE 13.12deg, #6860F9 175.8deg, rgba(31, 185, 166, 0.49) 324.38deg, rgba(39, 217, 180, 0.54) 338.47deg, #9397EE 373.13deg);
    border-radius: 30px;
    visibility: hidden;
    height: 10px;
    width: 10px;
    z-index: -1;
}

.type1:hover::after {
    visibility: visible;
    transform: scale(100) translateX(2px);
}

.note {
    color: #898989;
    font-size: small;
}

/* .login {
    width: 100%;
    height: 100%;
    position: absolute;
}

.login-left {
    width: 50%;
    float: left;
    height: 100%;
    position: relative;
}

.login-right {
    width: 50%;
    float: left;
    height: 100%;
    background-color: rgb(211, 211, 211);
    position: relative;
}

.login-right img {
    width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* 水平垂直居中 */
.login-element {
    width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-element div {
    margin-top: 20px;
}

.login-left img {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
}

.login-button a {
    background-color: rgb(211, 211, 211);
    text-decoration: none;
    color: black;
    font-size: 20px;
    border-radius: 5px;
    margin: 10px;
    padding: 5px 10px;
}

.clause input {
    float: left;
}

.clause p {
    margin-left: 10px;
    /* 或者使用 padding-left: 10px; 代替 margin-left: 10px; */
}

.main {
    width: 100vw;
    height: 100vh;
    position: absolute;
    /* background-color: rgb(244,245,249); */
}

/* .topbar {
    width: 100%;
    height: 80px;
    background-color: rgb(161, 161, 161);
} */

.progress-bar {
    width: 75vw;
    height: 80px;
    position: absolute;
    top: 20px;
    right: 5vw;
    z-index: 10;
    background-color: #fff;
    border-radius: 10px;
}

.progress-box {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.progress-bar-section-box {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.progress-bar-section-text-box {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.progress-bar-section-text-box div {
    width: 120px;
    height: auto;
    float: left;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-left: calc(25% - 150px);
}

.progress-bar-section-text-box div:first-child {
    margin-left: 0;
}

.circle {
    width: 35px;
    height: 35px;
    margin: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D1D1D1;
    color: #fff;
}

.circle:first-child {
    margin-left: 30px;
}

.circle:last-child {
    margin-right: 30px;
}

.circle-active {
    background-color: #5325B8;
}

.circle-finish {
    background-color: #32D7B7;
}

.line {
    width: calc(25% - 78.75px);
    height: 10px;
    border-radius: 5px;
    background: rgba(209, 209, 209, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
}

@keyframes widthAnimation {
    0% {
        width: 40%;
    }

    25% {
        width: 45%;
    }

    50% {
        width: 40%;
    }

    75% {
        width: 45%;
    }

    100% {
        width: 40%;
    }
}

.line-active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    background: #6C47BD;
    /* animation: widthAnimation 4s cubic-bezier(0.83, -0.01, 0.12, 0.99) infinite */
}


.line-finish {
    background-color: #B3EFDD;
}

.upload-space {
    width: 100%;
    height: calc(100% - 160px);
    position: relative;
    display: none;
}

.work-space {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: rgb(244, 245, 249);
}

.gallary-box {
    width: 20vw;
    height: 100vh;
    float: left;
    position: relative;
}

.gallary {
    width: 90%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #F7FDFD;
    /* border-radius: 10px; */
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    color: #4905B5;
}

.selection-context {
    width: 100%;
    height: 40px;
    position: relative;
    padding-left: 10px;
    font-size: 24px;
    font-weight: 400;
    color: #4B14B5;
    text-align: left;
    border: none;
    background: none;
    outline: none;
}

.selection-context:hover,
.selection-context:focus {
    cursor: pointer;
    color: #03b5d2;
}

.selection-context:hover::after,
.selection-context:focus::after {
    cursor: pointer;
    color: #03b5d2;
    border: 1px solid #03b5d2;
}

.selection-icon {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 4px;
    width: 22px;
    height: 22px;
    border: 1px solid;
    border-radius: 22px;
}

.selection-icon::before {
    display: block;
    position: absolute;
    content: "";
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
}

.selection-icon::after {
    display: block;
    position: absolute;
    content: "";
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
}

.selection-context[aria-expanded=true] {
    color: #03b5d2;
}

.selection-context[aria-expanded=true] .selection-icon::after {
    width: 0;
}

.selection-context[aria-expanded=true]+.selection-scroll-box>.selection-container {
    opacity: 1;
    max-height: fit-content;
    position: relative;
    overflow: auto;
    transition: all 200ms linear;
    will-change: opacity, max-height;
}

.selection-container {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
}

.selection-scroll-box {
    position: relative;
    max-height: calc(100vh - 173px);
    overflow: auto;
}


.gallary-element {
    width: 100%;
    height: 85%;
    position: relative;
    /* background-color: aliceblue; */
    /* 超出后加滚动条 */
    /* overflow-y: scroll; */
    /* 修改滚动条背景颜色 */
}

.g-element {
    /* 内边距 */
    /* padding: 5px; */
    position: relative;
    width: 80%;
    /* 上下边距5px,左右边距10px */
    margin: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
}

.g-element-active {
    border: 1px solid #4905B5;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.selection-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.g-element-img {
    width: 60%;
    /* border-radius: 5px; */
    /* background-color: rgb(185, 185, 185); */
}

.g-element-img img {
    width: 100%;
    height: auto;
}

.g-element p {
    font-size: large;
    font-weight: 800;
    margin-right: 20px;
}

.content-box {
    width: 40vw;
    height: 100%;
    float: left;
    position: relative;
    /* background-color: aliceblue; */
}

.content {
    width: 100%;
    height: 80%;
    position: absolute;
    bottom: 40px;
    /* background-color: rgb(175, 175, 175); */
    border-radius: 20px;
}

.content-content {
    width: 100%;
    height: auto;
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.canvas-container {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    /* 更改下边框阴影 */
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.5);
    /* border-radius: 20px; */
}

.play-component {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* 左上、右上没有圆角，左下、右下有圆角 */
    border-radius: 0 0 20px 20px;
    background-color: #F7FDFD;
}

.play-button {
    border-radius: 50%;
    background: #A1A1A1;
    width: 50px;
    height: 50px;
    /* display: inline-block; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
#play-progress-bar {
    width: calc(100% - 250px);
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
}

#play-progress {
    width: 100%;
    height: 100%;
    /* background-color: #4caf50; */
    /* transition: width 0.3s ease; */
}

.play-jumper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.play-jumper input {
    /* 输入居中 */
    text-align: center;
    /* 更改输入框边框颜色 */
    border-color: #8261C8;
}

#number-input {
    width: 20px;
    display: inline-block;
}

.image-play {
    text-align: center;

}

.canvas-elements {
    margin: 20px;
    text-align: center;
}

.canvas-button {
    border-radius: 50px;
    background: #A1A1A1;
    box-shadow: 20px 20px 60px #898989,
        -20px -20px 60px #b9b9b9;
}

.canvas-button:hover {
    border-radius: 50px;
    background: #A1A1A1;
    box-shadow: inset 20px 20px 60px #898989,
        inset -20px -20px 60px #b9b9b9;
}

.config-box {
    width: 40vw;
    height: 100%;
    float: left;
    position: relative;
    /* background-color: aliceblue; */
}

.config {
    width: 70%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgb(175, 175, 175); */
}

.config-card {
    width: 100%;
    height: auto;
    background-color: #F7FDFD;
    border-radius: 20px;
    margin: 15px;
}

.config-card div {
    padding: 10px;
}

#drop-area {
    width: 300px;
    height: 200px;
    border: 2px dashed #ccc;
    text-align: center;
    font-family: sans-serif;
    margin: 0px auto;
}

#drop-area.highlight {
    background-color: #e8f1ff;
}

#file-input {
    display: block;
    margin: 0 auto;
}

.page-container {
    padding: 200px 100px 100px 100px;
}

@media screen and (max-width: 768px) {
    .page-container {
        padding: 200px 30px 100px 30px;
    }
}

.header-background {
    
}