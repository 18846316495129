@font-face {
  font-family: 'Work Sans Thin';
  src: url('css/WorkSans-ExtraLight.ttf');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('css/WorkSans-Light.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans light';
  src: url('css/OpenSans-Light.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans bold';
  src: url('css/OpenSans-Bold.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans medium';
  src: url('css/OpenSans-Medium.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans regular';
  src: url('css/OpenSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans semibold';
  src: url('css/OpenSans-SemiBold.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans';
}

.home-box {
  margin-top: 80px;
  padding: 10vw;
  width: 100%;
  position: relative;
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  text-align: center;
}

.h1 {
  color: #474ED2;
  -webkit-user-select: none;
  user-select: none;
  font-family: 'Work Sans Thin';
  margin: 5px;
  font-style: normal;
  font-weight: 100;
  text-shadow: 0px 1.84083px 1.84083px rgba(0, 0, 0, 0.25);
  font-size: max(5vw, 36px);
}

.home-content p {
  width: max(50%, 350px);
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: max(1vw, 16px);
  line-height: 152.3%;
  margin-bottom: 44px;
  margin-top: 10px;
  color: #444BD5;
}

.upload-button {
  height: 50px;
  width: 200px;
  position: relative;
  cursor: pointer;
  border: none;
  overflow: hidden;
  border-radius: 30px;
  transition: all 0.5s ease-in-out;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-shrink: 0;
  /* background: linear-gradient(192deg, #A093EE 15.04%, #8D34FF 32.33%, rgba(99, 124, 255, 0.76) 53.87%, rgba(116, 255, 226, 0.84) 77.89%, rgba(0, 255, 224, 0.49) 89.96%); */
  background: linear-gradient(12deg, rgba(0, 255, 224, 0.49), rgba(116, 255, 226, 0.5), rgba(99, 124, 255, 0.76), rgba(141, 52, 255, 0.8), rgba(160, 147, 238, 0.8));
  background: linear-gradient(192deg, rgba(160, 147, 238, 0.6) 15.04%, rgba(141, 52, 255, 0.4) 32.33%, rgba(99, 124, 255, 0.4) 53.87%, rgba(116, 255, 226, 0.54) 77.89%, rgba(0, 255, 224, 0.49) 89.96%);
}

.btn-txt {
  z-index: 1;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Work Sans';
  /* 字间距 */
  letter-spacing: 0.08em;
}

/* animition from color #551A8B to #fff */
@keyframes button-colorTransition {
  from {
    color: #551A8B;
  }

  to {
    color: #fff;
  }
}

.upload-button:hover {
  /* box-shadow: 1px 1px 200px #252525; */
  /* border: none; */
  /* width: 240px; */
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);

  /* height: 55px; */
  /* box-shadow: 1px 1px 200px #252525; */
}

/* .upload-button:hover .btn-txt {
  animation: button-colorTransition 0.5s ease-in-out forwards;
  -webkit-animation: button-colorTransition 0.5s ease-in-out forwards;
} */

.type1::after {
  content: "";
  position: absolute;
  left: 0;
  top: -76px;
  transition: all 0.5s ease-in-out;
  /* background-color: #6A63ED; */
  /* background: conic-gradient(from 179.03deg at 37.84% 46.85%, rgba(39, 217, 180, 0.54) -21.53deg, #9397EE 13.12deg, #6860F9 175.8deg, rgba(31, 185, 166, 0.49) 324.38deg, rgba(39, 217, 180, 0.54) 338.47deg, #9397EE 373.13deg); */
  border-radius: 30px;
  visibility: hidden;
  height: 10px;
  width: 10px;
  z-index: -1;
  visibility: visible;
  transform: scale(100) translateX(2px);
}

.note {
  margin-top: 10px;
  color: #494949;
  font-size: 12px;
}

.projects {
  min-height: 400px;
  margin-bottom: 50px;
}

#user-projects {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}

.user-projects-class {
  color: #474ED2;
  font-size: 22px;
  font-weight: 400;
  margin-left: 40px;
}

.user-projects-title {
  display: inline-block;
  padding: 20px 40px;
  border-radius: 30px 30px 0 0;
  background-color: #F8FBF9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.user-projects-title:hover {
  cursor: pointer;
}

.user-projects-title-active {
  background-color: #EAECFE;
}

#user-projects .projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  padding: 20px 20px;
}

#user-projects .project {
  width: 320px;
  height: 250px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 20px 20px;
  overflow: hidden;
  position: relative;
}

.projectImg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.deleteImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ADADAD;
  position: absolute;
  top: 15px;
  right: 10px;
}

.deleteImg:hover {
  background-color: #f04040;
}

.deleteImg img {
  width: 30px;
  height: auto;
  margin-top: 8px;
  margin-left: 5px;
}

.prject-time {
  position: absolute;
  top: 15px;
  left: 10px;
  background-color: #474FFF;
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  font-weight: 800;
}

.project-content {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  width: 100%;
  height: 35%;
  padding-left: 20px;
}

.project-content>div {
  max-width: 70%;
}

.project-content-name {
  font-size: 24px;
  color: #4200FF;
  max-width: 60%;
  overflow: hidden;
  display: inline;
}

.project-content input {
  max-width: 60%;
  border: 0;
  margin: 5px 0;
  font-size: 24px;
  color: #4200FF;
}

.project-content .imgIcon {
  width: 20px;
}

.project-content-description {
  font-size: 16px;
  color: #373B7B;
  width: 75%;
  min-height: 16px;
}

.project-content-description p {
  font-size: 16px;
  color: #373B7B;
  width: 75%;
}

.project-time-last {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #474FFF;
  font-weight: 800;
}

.project-time-last::before {
  position: absolute;
  content: 'Last edited by';
  top: -15px;
  right: 0;
  font-size: 12px;
  color: #474FFF;
}

.project-process {
  position: absolute;
  bottom: calc(35% - 12.5px);
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.project-process>div {
  margin: 0 5px
}

.project-process-finish {
  width: 20px;
  height: 20px;
  background-color: #32D7B7;
  border-radius: 10px;
}

.project-process-active {
  width: 25px;
  height: 25px;
  background-color: #32D7B7;
  border-radius: 12.5px;
}

.project-process-todo {
  width: 20px;
  height: 20px;
  background-color: #474FFF;
  border-radius: 10px;
}

.project-finish {
  position: absolute;
  bottom: calc(35% - 30px);
  right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #32D7B7;
}

.project-uploading {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(71, 75, 150, 0.29);
}

.project-uploading-processing{
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #474FFF;
  font-size: 24px;
  font-weight: 400;
  z-index: 10;
}

.project-uploading-processing::before {
  content: 'Uploading... ';
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 12px;
  color: #474FFF;
}

.project-uploading-bar {
  max-width: 100% !important;
  width: 100%;
  height: 10px;
  background-color: #EDF2FE;
  position: absolute;
  top: 0;
  right: 0;
}

.project-uploading-bar-value {
  width: 0%;
  height: 100%;
  background-color: #474FFF;
  position: absolute;
  border-radius: 0 5px 5px 0;
  top: 0;
  left: 0;
  animation: progress 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.project-uploading-img {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 115px;
  top: 48px;
  animation: rotation 2s linear  infinite;
}

.project-uploading-name {
  position: absolute;
  bottom: 40px;
  left: 50%;
  color: #474FFF;
  font-size: 20px;
  font-weight: 600;
  transform: translateX(-50%);
}

/* 水平垂直居中 */
.login-element {
  width: 80%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-element div {
  margin-top: 20px;
}

.login-left img {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.login-button a {
  background-color: rgb(211, 211, 211);
  text-decoration: none;
  color: black;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px;
  padding: 5px 10px;
}

.clause input {
  float: left;
}

.clause p {
  margin-left: 10px;
  /* 或者使用 padding-left: 10px; 代替 margin-left: 10px; */
}

.main {
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* background-color: rgb(244,245,249); */
}

/* .topbar {
  width: 100%;
  height: 80px;
  background-color: rgb(161, 161, 161);
} */

.progress-bar {
  width: 75vw;
  height: 10vh;
  position: absolute;
  top: 3vh;
  right: 5vw;
  z-index: 10;
  background-color: #fff;
  border-radius: 10px;
  font-family: 'Open Sans regular';
}

.progress-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.progress-bar-section-box {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.progress-bar-section-text-box {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.progress-bar-section-text-box div {
  width: 120px;
  height: auto;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-left: calc(25% - 150px);
}

.progress-bar-section-text-box div:first-child {
  margin-left: 0;
}

.circle {
  width: 35px;
  height: 35px;
  margin: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D1D1D1;
  color: #fff;
  transition: all 0.5s;
}

.circle:first-child {
  margin-left: 30px;
}

.circle:last-child {
  margin-right: 30px;
}

.circle-active {
  background-color: #5325B8;
  width: 40px;
  height: 40px;
  transition: all 0.5s;
}

.circle-finish {
  background-color: #32D7B7;
  transition: all 0.5s;
}

.line {
  width: calc(25% - 78.75px);
  height: 10px;
  border-radius: 5px;
  background: rgba(209, 209, 209, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
}

@keyframes widthAnimation {
  0% {
    width: 0%;
  }

  100% {
    width: 50%;
  }
}

.line-active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #6C47BD;
  border-radius: 5px;
  animation: 2s widthAnimation 2s cubic-bezier(0.83, -0.01, 0.12, 0.99) forwards;
}

@keyframes finishWidthAnimation {
  0% {
    background-color: #6C47BD;
    width: 50%;
  }

  100% {
    background-color: #B3EFDD;
    width: 100%;
  }
}

.line-finish {
  background: rgba(209, 209, 209, 0.2);
  transition: all 0.5s;
}

.line-finish::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #6C47BD;
  animation: 2s finishWidthAnimation cubic-bezier(0.83, -0.01, 0.12, 0.99) forwards;
}

.upload-space {
  width: 100%;
  height: calc(100% - 160px);
  position: relative;
  display: none;
}

.work-space {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-color: rgb(244, 245, 249);
  ;
}

.gallary-box {
  width: 20vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.gallary {
  width: 90%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #F7FDFD;
  color: #4905B5;
  font-weight: 800;
}

.selection-title {
  font-size: 26px;
  font-family: 'Open Sans semibold';
  font-weight: 800;
  color: #000;
}

.imgIcon {
  width: 23px;
  margin-left: 10px;
}

.selection-context {
  width: 100%;
  height: 60px;
  position: relative;
  padding-left: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #4B14B5;
  text-align: left;
  border: none;
  background: none;
  outline: none;
}

.selection-context:hover,
.selection-context:focus {
  cursor: pointer;
  color: #03b5d2;
}

.selection-context:hover::after,
.selection-context:focus::after {
  cursor: pointer;
  color: #03b5d2;
  border: 1px solid #03b5d2;
}

.expandIcon {
  width: 20px;
  position: absolute;
  right: 10px;
  top: 14px;
}

.selection-icon {
  width: 20px;
  position: absolute;
  right: 10px;
  top: 25px;
}

.selection-num {
  width: 20px;
  position: absolute;
  right: 40px;
  top: 14px;
  font-family: 'Open Sans semibold';
  color: #000;
}

.selection-context[aria-expanded=true] {
  color: #03b5d2;
}

.selection-context[aria-expanded=true] .selection-icon {
  transform: rotate(0deg);
  transition: all 200ms linear;
}

.selection-context[aria-expanded=false] .selection-icon {
  transform: rotate(90deg);
  transition: all 200ms linear;
}

.selection-context[aria-expanded=true]+.selection-scroll-box>.selection-container {
  opacity: 1;
  max-height: fit-content;
  position: relative;
  overflow: auto;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.selection-container {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.selection-scroll-box {
  position: relative;
  max-height: calc(100vh - 220px);
  overflow: auto;
}


.gallary-element {
  width: 100%;
  height: 85%;
  position: relative;
}

.g-element {
  /* 内边距 */
  padding: 5px 0px;
  position: relative;
  width: 80%;
  /* 上下边距5px,左右边距10px */
  margin: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  border-radius: 5px;
}

.g-element:hover {
  cursor: pointer;
  background-color: #b6eeee;
}

.g-element-reference {
  margin: 3px;
  z-index: -1;
}

.g-element-reference-active {
  z-index: 1;
}

.fiducial {
  padding: 15px 30px 15px 0px;
  color: #000;
  font-weight: 400;
  font-size: 20px;
}

.fidcial-green {
  margin-right: 20%;
  color: #56E47E;
}

.fidcial-yellow {
  margin-right: 20%;
  color: #DDAD00;
}

.fidcial-red {
  margin-right: 20%;
  color: #EB676F;
}

.g-element-active {
  /* border: 1px solid #4905B5; */
  /* box-sizing: border-box; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background-color: #e3e3f5;
}

.selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.g-element-img {
  width: 60%;
  /* border-radius: 5px; */
  /* background-color: rgb(185, 185, 185); */
}

.g-element-img img {
  width: 100%;
  height: auto;
}

.g-element p {
  font-size: large;
  font-weight: 800;
  margin-right: 20px;
}

.content-box {
  width: 40vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 20vw;
  padding-top: 13vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: aliceblue; */
}

.content {
  width: 100%;
  height: auto;
}

.content-content {
  width: 100%;
  height: auto;
}

.canvas-container {
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  position: relative;
  /* 更改下边框阴影 */
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.5);
  /* border-radius: 20px; */
}

.play-component {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* 左上、右上没有圆角，左下、右下有圆角 */
  border-radius: 0 0 20px 20px;
  background-color: #F7FDFD;
}

.play-button {
  width: 40px;
  height: 40px;
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 2px;
  cursor: pointer;
}

#play-progress-bar {
  width: calc(100% - 350px);
  height: 20px;
  overflow: hidden;
  margin: 0 20px;
  padding: 0 10px;
}

#play-progress {
  width: 100%;
  height: 100%;
}

.play-jumper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-jumper input {
  text-align: center;
  border-color: #8261C8;
}

#number-input {
  width: 47px;
  height: 33px;
  border-radius: 10px;
  border: #5325B8 3px solid;
  margin-right: 5px;
  display: inline-block;
  font-size: 20px;
}

.total-image-number {
  font-size: 23px;
}

.image-play {
  text-align: center;

}

.canvas-elements {
  margin: 20px;
  text-align: center;
}

.canvas-button {
  border-radius: 50px;
  background: #A1A1A1;
  box-shadow: 20px 20px 60px #898989,
    -20px -20px 60px #b9b9b9;
}

.canvas-button:hover {
  border-radius: 50px;
  background: #A1A1A1;
  box-shadow: inset 20px 20px 60px #898989,
    inset -20px -20px 60px #b9b9b9;
}

.config-box {
  width: 40vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 13vh;
  /* background-color: aliceblue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.config {
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: rgb(175, 175, 175); */
}

.config-card {
  width: 83%;
  height: fit-content;
  background-color: #F7FDFD;
  border-radius: 10px;
  font-family: 'Open Sans semibold';
}

.next-card {
  width: 257px;
  height: 75px;
  display: block;
  cursor: pointer;
}

.next-card-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.config-title {
  text-align: center;
  font-size: 29px;
  font-weight: 800;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  color: #5325B8;
}

.config-content-box {
  position: relative;
  width: 90%;
  background-color: #fff;
  margin: 20px auto;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: 'Open Sans regular';
  transition: all 0.3s ease-in-out;
}

.config-content-box:hover {
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.5);
}

.config-content {
  position: relative;
  color: #070707;
  font-family: 'Open Sans regular';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0px;
}

.half {
  width: 50%;
  display: inline-block;
}

.config-input {
  width: 246.343px;
  height: 30px;
  border: 1px solid #5325B8;
  border-radius: 7px;
  padding: 0 10px;
  border-radius: 6.316px;
  border: 0.902px solid #5325B8;
  background: #FFF;
  box-shadow: 0.9023569226264954px 3.6094276905059814px 5.414141654968262px 0px rgba(0, 0, 0, 0.25) inset;
}

.config-content-redo-undo-box {
  position: relative;
  width: 90%;
  margin: 20px auto;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.config-detection {
  color: #5325B8;
}

.config-tracking-info-box {
  position: relative;
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
}

.config-tracking-info {
  position: relative;
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #fff;
}

.config-tracking-info-title-box {
  display: inline-block;
  background: rgba(226, 226, 226, 0.35);
  border-radius: 6px 6px 0px 0px;
  padding: 10px 5px 7px 5px;
  color: #949494;
  cursor: pointer;
}

.config-tracking-info-title-box-active {
  background: rgba(212, 186, 255, 0.35);
  border-radius: 6px 6px 0px 0px;
  padding: 10px 5px 7px 5px;
  color: #6C47BD;
}

.config-tracking-info-title-box:first-child {
  margin-left: 10px;
  margin-right: 3px;
}

.config-tracking-info-title {
  border-bottom: 1px solid #5325B8;
}

.config-tracking-error-box {
  padding: 10px 20px 10px 20px;
  background-color: #F4F4FF;
  border-radius: 10px;
  height: 100px;
}

.config-tracking-error-box-box {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.config-tracking-error {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
}

.config-tracking-error-item {
  width: 30px;
  height: 25px;
  margin: 4px 2px;
  background-color: #fff;
  border-radius: 5px;
  border: #000 solid 1px;
}

.config-tracking-error-item-active {
  background-color: #B3EFDD;
}

.config-tracking-info-text {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  color: #5325B8;
}

.yellow {
  color: #DDAD00;
}

.center {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.column {
  flex-direction: column
}

.left {
  text-align: left;
  width: fit-content;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.SCZ-button {
  display: inline-block;
  width: 33.33%;
  height: 60px;
}

/* .CAD-button {
  display: inline-block;
  width: 33.33%;
  height: 60px;
} */

.RU-button {
  /* float: right; */
  width: 60px;
  height: 50px;
}

.config-content-text {
  display: inline-block;
  width: 33.33%;
  text-align: center;
  color: #B2B2B2;
  font-size: 12px;
}

.config-content-text-active {
  color: #070707;
}

.box-2-1 {
  width: 50%;
  height: 100%;
}

#drop-area {
  width: 300px;
  height: 200px;
  border: 2px dashed #ccc;
  text-align: center;
  font-family: sans-serif;
  margin: 0px auto;
}

#drop-area.highlight {
  background-color: #e8f1ff;
}

#file-input {
  display: block;
  margin: 0 auto;
}

.page-container {
  padding: 200px 100px 100px 100px;
}

@media screen and (max-width: 768px) {
  .page-container {
    padding: 200px 30px 100px 30px;
  }
}

.page-loader {
  z-index: 11;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.page-loader-box {
  width: 484px;
  height: 301px;
  background: #FFF;
  border-radius: 40px;
}

.page-loader-img {
  height: 40%;
  position: relative;
  margin-top: 8%;
}

@keyframes gear-animation {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes gear-animation-reverse {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: -360deg;
  }
}

.gear {
  position: absolute;
  animation: gear-animation infinite 3s linear;
}

.gear-p1 {
  height: 70px;
  top: 30px;
  left: 228px;
}

.gear-p2 {
  height: 28px;
  top: 21px;
  left: 189px;
  animation: gear-animation-reverse infinite 3s linear;
}

.page-loader-bar {
  color: #4200ff;
  font-weight: 600;
}

.page-loader-bar-box {
  width: 360px;
  height: 24px;
  border-radius: 39.5px;
  background: #E4E4E4;
  overflow: hidden;
}

.page-loader-bar-value {
  width: 0%;
  height: 100%;
  border-radius: 39.5px;
  /* background: linear-gradient(-90deg, #A093EE, #8D34FF, #637CFFC2, #74FFE2D6, #00FFE07D); */
  background-color: #637CFFC2;
}

.page-loader-bar-info {
  margin-left: 63px;
  text-align: left;
}

.page-loader-bar-progress {
  text-align: right;
  margin-right: 65px;
  margin-top: 10px;
  margin-bottom: 5px;
}

@keyframes lens-animation {
  0% {
    transform: translateX(8px) translateY(0px);
  }

  25% {
    transform: translateX(0px) translateY(8px);
  }

  50% {
    transform: translateX(-8px) translateY(0px);
  }

  75% {
    transform: translateX(0px) translateY(-8px);
  }

  100% {
    transform: translateX(8px) translateY(0px);
  }
}

.lens {
  width: 46px;
  height: 59px;
  top: 35px;
  left: 228px;
  position: absolute;
  animation: lens-animation infinite 3s ease-in-out;
}

.form {
  position: relative;
}


@keyframes detection-arrow-animation {
  0% {
    top: 60px;
  }

  50% {
    top: 40px;
  }

  100% {
    top: 60px;
  }
}

.detection-arrow {
  position: absolute;
  width: 50px;
  top: 60px;
  left: 193px;
  animation: detection-arrow-animation infinite 3s ease-in-out;
}

@keyframes rectangle-animation {
  0% {
    height: 50px;
  }

  50% {
    height: 30px;
  }

  100% {
    height: 50px;
  }
}

.rectangle {
  position: absolute;
  bottom: 30px;
  width: 15px;
  height: 40px;
  background-color: rgb(71, 79, 255);
  border-radius: 3px 3px 0 0;
}