@font-face {
  font-family: 'ALBB-L';
  src: url('css/static/ALBB_Light.woff');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'ALBB-R';
  src: url('css/static/ALBB_Regular.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'ALBB-B';
  src: url('css/static/ALBB_Bold.woff');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ALBB-L {
  font-family: 'ALBB-L';
}

.react-parallax-bgimage {
  position: relative;
  height: 100vh;
}

.divide {
  padding-left: 10vw;
  align-items: flex-start !important;
  text-align: left;
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  text-align: center;
  height: 100vh;
  width: 100%;
}

.home-content>div {
  max-width: 800px;
}

.home-content div.sh1 {
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 50px;
  font-size: 77.117px;
  font-style: normal;
  font-weight: 800;
  line-height: 129.639%;
}

.home-content div.sh2 {
  color: #fff;
  font-size: 3rem;
  letter-spacing: 1px;
  line-height: 1.3;
  font-weight: 800;
  margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
  .home-content div.sh1 {
    font-size: 70px;
  }

  .home-content div.sh2 {
    font-size: 2rem;
  }

}

.home-content div.shs {
  color: #fff;
  letter-spacing: 3px;
  color: #FFF;
  /* font-family: Alegreya Sans; */
  font-size: 16.966px;
  font-style: normal;
  font-weight: 500;
  line-height: 129.639%;
}

.home-content div.shp {
  color: #000;
  letter-spacing: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.639%;
}

.home-content .normol-button {
  background-color: transparent;
  border: #fff 2px solid;
  border-radius: 5px;
  padding: 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  transition: all 0.3s;
  margin-bottom: 10px;
}

.home-content .normol-button:hover {
  background-color: #fff;
  border: #fff 2px solid;
  border-radius: 5px;
  padding: 1rem;
  color: #000;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 10px;
}

.home-content .normol-button-black {
  background-color: #5B38E5;
  border: #5B38E5 2px solid;
  border-radius: 5px;
  padding: 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  transition: all 0.3s;
  margin-bottom: 10px;
}

.home-content .normol-button-black:hover {
  background-color: #fff;
  border: #000 2px solid;
  border-radius: 5px;
  padding: 1rem;
  color: #000;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 10px;
}

.normol-button-black-contact {
  background-color: #563adc;
  border: #fff solid 2px;
  border-radius: 5px;
  padding: 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  transition: all 0.3s;
  margin-bottom: 10px;
  display: inline-block;
}

.normol-button-black-contact:hover {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  color: #563adc;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 10px;
}

.home-content-text {
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.5rem;
  letter-spacing: 10px;
}

.home-logo>img {
  width: 183px;
  height: 55px;
}

.home-nav {
  background-color: #060024;
  position: relative;
  z-index: 10;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.home-nav>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 1600px;
  margin: auto;
}

.home-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.mobile-menu-icon {
  display: none;
}

@keyframes nav-bar-in {
  0% {
    transform: translate(100%, 0%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

.home-link-mobile {
  position: fixed;
  z-index: 10;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  animation: nav-bar-in 1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 24px;
}

.home-link-mobile>a {
  color: #fff;
  text-decoration: none;
  padding: 1rem;
}

.home-link-mobile>a:hover {
  color: #fff;
  text-decoration: none;
  padding: 1rem;
  border-bottom: 2px solid #fff;
}

.home-link>a {
  color: #fff;
  text-decoration: none;
  padding: 1rem;
}

.home-link>a:hover {
  color: #fff;
  text-decoration: none;
  padding: 1rem;
  border-bottom: 2px solid #fff;
}

@media screen and (max-width: 800px) {
  .home-link {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }
}

/* .CircularProgressbar {
  width: 100px !important;
  color: #fff !important;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.CircularProgressbar-text {
  fill: #fff !important;
  font-size: 16px !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #A792F9 !important;
} */

.divide-two {
  width: 50%;
  display: inline-block;
  text-align: left;
  position: relative;
}

.dt40 {
  width: 40%;
}

.dt60 {
  width: 60%;
}

.divide-two>img {
  width: 90%;
  height: 90%;
}

.home-video {
  /* width: 33%; */
  width: 100%;
}

.flex-center {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.home7-box {
  width: 180px;
  height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.home7-box>img {
  height: 100px;
  margin: 20px 0;
}

@media screen and (max-width: 800px) {
  .divide-two {
    width: 100%;
    display: block;
    text-align: left;
  }

  .dt40,
  .dt60 {
    width: 100%;
  }

  .home-video {
    width: 100%;
  }

  .home7-box {
    width: 100%;
    height: 300px;
  }

  .flex-center {
    flex-direction: column;
  }
}

.footer1 {
  width: min(30%, 400px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  text-align: left;
  margin: 50px 0;
}

.footer1>img {
  width: min(300px, 100%);
  margin-bottom: 30px;
}

.footer1>a {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem;
}

.footer1>a:hover {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem;
  border-bottom: 2px solid #fff;
}

.footer1 span {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem;
}

@media screen and (max-width: 800px) {
  .footer1 {
    width: 80%;
  }
}

.change-home-bg-position {
  background: url('./img/home-bg1.jpg') no-repeat center center fixed;
  background-size: cover;
}

.nct {
  color: #000;
  margin-bottom: 50px;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 165.639%;
  transition: all 0.3s;
}

.nct:hover {
  /* font-size: 36px; */
  transform: translateY(-10px);
}

.ncp {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.639%;
}

.news-time {
  color: #787878;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.639%;
  /* position: absolute;
  bottom: 0;
  right: 0; */
}

.news-box {
  position: relative;
}

.news-box-img {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.news-box-img img {
  width: 510px;
  height: 340px;
  transition: all 0.3s;
}

.news-box-img img:hover {
  width: 600px;
  height: 400px;
}

@media screen and (max-width: 800px) {
  .news-box-img img {
    width: 81vw;
    height: 54vw;
    transition: all 0.3s;
  }

  .news-box-img img:hover {
    width: 90vw;
    height: 60vw;
  }
}

.news-page-button {
  width: 30px;
  height: 30px;
  margin: 10px;
  border: none;
  background-color: #fff;
  color: #000;
}

.news-page-button-activate {
  background-color: #525AF2;
  color: #fff;
}

.circle-box {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: #B7ADD4 10px solid;
  background-color: #fff;
  position: absolute;
  color: #693EA0;
  font-size: 24px;
  font-weight: 800;
  padding: 10px;
  text-align: center;
  line-height: 120%;
  transition: all 0.3s;
}

.circle-box:hover {
  background-color: #693EA0;
  color: #fff;
}

.pipeline-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px 0;
}

.pipeline-container>div {
  max-width: 1500px;
  position: relative;
  width: 90vw;
  margin: 0 0 0 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.pipeline-table {
  width: 100%;
}

.pipeline-header {
  width: 100%;
}

.pipeline-header-th {
  font-size: 1.3rem;
  color: #fff;
  width: 12.5%;
  height: 80px;
  min-width: 140px;
  font-weight: 600;
  position: relative;
  vertical-align: middle;
}


@media screen and (max-width: 900px) {
  .pipeline-header {
    width: fit-content;
  }

  .pipeline-header-th {
    width: 80px;
  }
}

.pipeline-section {
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17.621px;
  font-style: normal;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.41);
  ;
}

.pipeline-section>td {
  padding: 10px 0;
  border-radius: 27.753px;
  line-height: 165.639%;
}

.pipeline-progress {
  position: relative;
}

.pipeline-progress-td {
  padding: 30px 0 20px 0;
  vertical-align: middle;
}

.pipeline-progress-first-td {
  position: relative;
  border-left: none;
  color: #00FFD1;
  font-family: Inter;
  font-size: 17.621px;
  font-style: normal;
  font-weight: 600;
  line-height: 165.639%;
  cursor: pointer;
  text-decoration: none;
}

.pipeline-progress-first-td:hover {
  text-decoration: underline;
}

.pipeline-description {
  display: none;
  position: absolute;
  bottom: 90%;
  width: 350px;
  height: fit-content;
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.pipeline-description::after {
  content: '';
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 25%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent #000 #000 #000;
}

.pipeline-progress-second-td {
  border-right: none;
  color: #E3DCFF;
  text-align: center;
  font-family: Inter;
  font-size: 17.621px;
  font-style: normal;
  font-weight: 600;
  line-height: 165.639%;
  position: relative;
}

.pipeline-done {
  width: 95%;
  height: 20px;
  border-radius: 0px 26.432px 26.432px 0px;
  background: linear-gradient(270deg, #71E6D1 11.66%, rgba(113, 230, 209, 0.00) 92.28%);
}


.pipeline-link {
  color: #fff;
  font-size: 20px;
  padding-top: 70px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.pipeline-link::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.5px;
  position: absolute;
  bottom: 7px;
  background: #fff;
  left: 0%;
  opacity: 1;
  /* border-bottom: 1px dashed #000; */
  /* animation: pipeline-link-underline-animation 3s infinite; */
  /* animation: pipeline-link-underline-up-animation 3s infinite; */
}

@keyframes pipeline-link-underline-up-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pipeline-link-underline-animation {
  0% {
    left: 0%;
  }

  100% {
    left: 100%;
  }
}

.pipeline-link:hover {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
}

@keyframes pipeline-animate-animation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
  
}

.pipeline-animate {
  animation: pipeline-animate-animation 2.5s forwards;
}